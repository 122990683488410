import * as React from "react";
import { Oval } from 'react-loading-icons'

const PlayPauseLoadingButton = ({ buttonState = 'paused' }) => {
  switch (buttonState) {
    case 'playing':
      return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16"><path d="M8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z"/><path d="M8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Z" fill="#fff"/><path d="M5,6.25a1.25,1.25,0,0,1,2.5,0v3.5a1.25,1.25,0,0,1-2.5,0Zm3.5,0a1.25,1.25,0,0,1,2.5,0v3.5a1.25,1.25,0,0,1-2.5,0Z"/></svg>
    
    case 'paused':
      return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16"><path d="M8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z"/><path d="M8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Z" fill="#fff"/><path d="M6.27,5.05a.51.51,0,0,1,.52,0l3.5,2.5a.5.5,0,0,1,.12.7.46.46,0,0,1-.12.12l-3.5,2.5A.5.5,0,0,1,6,10.5v-5A.5.5,0,0,1,6.27,5.05Z"/></svg>
    
    case 'loading':
      return <Oval stroke="#000" height="1.75em" strokeWidth={2} size={16} />
  
    default:
      return <div>huh?</div>
  }
};

export default PlayPauseLoadingButton;
/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Col } from 'react-bootstrap'
import { CaretLeftFill } from 'react-bootstrap-icons'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WaveformPlayer from '../components/waveform-player'
import WaveformPlaylist from '../components/waveform-playlist'
import classNames from 'classnames';
import { metadata, audioHero, audioHeroImage, audioHeroPlayer, audioHeroCaption, credit, backTo } from '../styles/pages.module.css'

const MusicPost = ( data ) => {
  const { frontmatter, body, excerpt } = data.pageContext.node
  const image = getImage(frontmatter.hero_image)
  const metaImage = frontmatter.hero_image
    ? frontmatter.hero_image.childImageSharp.resize
    : null
  const theme = frontmatter.player_theme || 'dark'
  const hideTrackLabel = frontmatter.hide_track_label || false
  const hero_audio = frontmatter.track || (frontmatter.playlist && frontmatter.playlist[0]);
  const waveform_player = React.createRef();
  const waveform_playlist = React.createRef();
  return (
    <Layout section="music">
      <SEO
          title={`music • ${frontmatter.title}`}
          description={frontmatter.description || excerpt}
          image={metaImage}
          pathname={data.path}
        />
      <Col md={9} lg={8} xl={7}>
        <figure className={audioHero}>
          <GatsbyImage className={classNames('rounded', audioHeroImage)} layout="fullWidth" image={image} alt={frontmatter.hero_image_alt} />
          { hero_audio &&
            <div className={audioHeroPlayer}>
              <WaveformPlayer track={hero_audio} ref={waveform_player} playlist={waveform_playlist} theme={theme} hideTrackLabel={hideTrackLabel} />
            </div>
          }
          <figcaption className={audioHeroCaption}>
            <Link to={frontmatter.hero_image_credit_link} className={credit} target="_blank">{frontmatter.hero_image_credit_text}</Link>
          </figcaption>
        </figure>
        <h1 className='mb-0'>{frontmatter.title}</h1>
        <span className={metadata}>{frontmatter.date}</span>
        { frontmatter.playlist && <WaveformPlaylist playlist={frontmatter.playlist} player={waveform_player} ref={waveform_playlist} /> }
        <MDXRenderer>
          {body}
        </MDXRenderer>
        <Link to="/music" className={backTo}>
          <CaretLeftFill />All Music
        </Link>
      </Col>
    </Layout>
  )
}

export default MusicPost
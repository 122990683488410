import React, { Component } from 'react';
import WaveformPlaylistItem from './waveform-playlist-item';
import classNames from 'classnames';
import { waveformPlaylist, waveformPlaylistHeader } from './../styles/waveform-player.module.css';

class WaveformPlaylist extends Component {
  state = {
    selectedIndex: 0
  }

  get tracks() {
    return this.props.playlist;
  }

  componentDidMount() {
  };

  componentWillUnmount() {
  }

  selectItem(index){
    return () => {
      this.setState({ selectedIndex: index });
      // const track = this.props.playlist[index];
      // this.props.player.current.load(track);
      this.props.player.current.loadTrackFromPlaylistIndex(index)
    }
  }
  
  render() {
    const { playlist } = this.props;
    const { selectedIndex } = this.state;
    return (
      <>
        <h2 className={waveformPlaylistHeader}>Tracks</h2>
        <div className={classNames("list-group", waveformPlaylist)}>
          { playlist.map((item, index) => <WaveformPlaylistItem key={index} name={item.name} url={item.url} selected={(selectedIndex === index)} select={this.selectItem(index)} />)}
        </div>
      </>
    );
  }
};

export default WaveformPlaylist;
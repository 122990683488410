import React, { Component } from 'react';
import classNames from "classnames";
import { PlayFill } from 'react-bootstrap-icons';
import { waveformPlaylistItem, waveformPlaylistIcon } from './../styles/waveform-player.module.css';

class WaveformPlaylistItem extends Component {
  state = {}

  componentDidMount() {
  };

  componentWillUnmount() {
  }

  load(url, name) {
    return () => {
      this.props.player.current.load(url, name);
    }
  }
  
  render() {
    const { name, selected, select } = this.props;
    const buttonClass = classNames("list-group-item list-group-item-action", { "active": selected }, { "disabled": selected }, waveformPlaylistItem);
    return (
      <button type="button" className={buttonClass} onClick={select}>
        <PlayFill className={waveformPlaylistIcon} />
        {name}
      </button>
    );
  }
};

export default WaveformPlaylistItem;
/* eslint-disable jsx-a11y/media-has-caption */

import React, { Component } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { Button } from 'react-bootstrap';
import PlayPauseLoadingButton from './play-pause-loading-button';
import { waveformContainer, playPauseButton, trackName, clickCatcher } from './../styles/waveform-player.module.css';

class WaveformPlayer extends Component {
  events = 'ready play pause finish'.split(' ');
  state = {
    loading: true,
    queueing: false,
    currentPlaylistIndex: 0
  }
  themes = {
    'dark': {
      progressColor: '#999',
      waveColor: '#000',
      cursorColor: '#000'
    },
    'light': {
      progressColor: '#aaaaaa',
      waveColor: '#ffffff',
      cursorColor: '#ffffff'
    }
  }
  cache = {};

  constructor(props) {
    super(props);
    this.state.track = props.track;
    this.clickCatcherRef = React.createRef();
  }

  /**
   * Component lifecycle
   */
  componentDidMount() {
    // const track = document.querySelector('#track');
    const { progressColor, waveColor, cursorColor } = this.themes[this.props.theme];

    this.waveform = WaveSurfer.create({
      barWidth: 1,
      barGap: 3,
      cursorWidth: 1,
      container: '#waveform',
      backend: 'MediaElement',
      height: 80,
      responsive: true,
      hideScrollbar: true,
      progressColor,
      waveColor,
      cursorColor,
    });
    this.events.forEach(event => this.waveform.on(event, this[event].bind(this)));
    this.load(this.state.track, false);
  };
  componentWillUnmount() {
    this.waveform.destroy();
  }
  listen(name) {
    return (event) => {
      console.log(`Waveform Player: ${name}`, event);
      this.waveform.on(name, this[name].bind(this))
    }
  }
  /**
   * Player Event handlers
   */
  ready() {
    console.log('ready');
    if( this.state.queueing ) {
      this.waveform.play();
    }
    this.setState({
      loading: false,
      queueing: false
    });
    this.waveform.drawer.on('click', this.onWaveformClick)
  }
  play() {
    console.log('play');
    this.setState({ playing: true });
  }
  pause() {
    console.log('pause');
    this.setState({ playing: false });
  }
  finish() {
    console.log('finish');
    this.setState({ playing: false });
    if(this.props.playlist && this.props.playlist.current) {
      const { currentPlaylistIndex } = this.state;
      console.log('currentPlaylistIndex', currentPlaylistIndex);
      this.props.playlist.current.selectItem(currentPlaylistIndex + 1)();
    } else {
      this.waveform.seekTo(0);
    }
  }
  onWaveformClick = (event) => {
    var rect = this.clickCatcherRef.current.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const width = this.clickCatcherRef.current.offsetWidth;
    const percent = offsetX / width;
    this.waveform.seekTo(percent);
  }  
  /**
   * Public methodsc
   */
  handlePlayPause = () => {
    this.waveform.playPause();
  }
  load(track, queue = true) {
    this.setState({ track, queueing: queue, loading: true });
    if(track.peaks && this.cache[track.peaks]) {
      console.log('Loading peaks from cache');
      this.waveform.load(track.url, this.cache[track.peaks]);
    } else if (track.peaks) {
      const endpoint = `/peaks/${track.peaks}.json`;
      console.log(`Loading peaks from ${endpoint}`);
      fetch(endpoint)
        .then(res => res.json())
        .then(peaks => {
          this.cache[track.peaks] = peaks.data;
          this.waveform.load(track.url, peaks.data);
        })
        .catch(err => console.error(err));
    } else {
      this.waveform.load(track.url)
    }
  }
  loadTrackFromPlaylistIndex(index) {
    if(!this.props.playlist || index >= this.props.playlist.current.tracks.length) return;
    const track = this.props.playlist.current.tracks[index];
    this.setState({
      currentPlaylistIndex: index
    });
    this.load(track);
  }
  
  /**
   * Render
   */
  render() {
    const { playing, track, loading } = this.state;
    const { name, url } = track;
    const { hideTrackLabel } = this.props;
    const buttonState = loading ? 'loading' : playing ? 'playing' : 'paused';
    return (
      <div className={waveformContainer}>
        <Button className={playPauseButton} variant="link" onClick={this.handlePlayPause}>
          <PlayPauseLoadingButton buttonState={buttonState} />
        </Button>
        <div onClick={this.onWaveformClick} ref={this.clickCatcherRef} className={clickCatcher} />
        <div id="waveform" />
        { !hideTrackLabel && name && <div className={trackName}>{name}</div> }
        <audio id="track" src={url} />
      </div>
    );
  }
};

export default WaveformPlayer;